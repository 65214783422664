
import type { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Box, useMediaQuery } from '@mui/material';
import LeftNavigationBar from './LeftNavigationBar';
import TopNavigation from './TopNavigation';
import useAuth from '../hooks/useAuth';
import { ModuleNames } from '../Static/constants';

const HCLEnterpriseLogo = '/images/logo.svg';

interface DashboardLayoutProps {
    children?: ReactNode;
}

const DashboardLayoutRoot = styled(Box)<{ $isMobile: boolean}>`
  display: flex;
  flex-direction: ${(props) => props.$isMobile ? 'column' : 'row'};
`;

const DashboardLayoutWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 751px){
    height: calc(95vh - 4rem);
  }
`;
const FooterContainer = styled.div`
`
const Footer = styled.div`
display: flex;
justify-content: flex-end;
margin: 0.5rem 0rem;
padding: 0rem 1.5rem;
gap: 1rem;
`

const DashboardLayout = (props: DashboardLayoutProps) => {
    const isMobile = useMediaQuery('(max-width: 751px)');
    const { user } = useAuth();

    return (
        <DashboardLayoutRoot $isMobile={isMobile}>
            <LeftNavigationBar />
            <DashboardLayoutWrapper>
                <span>
                {!isMobile && <TopNavigation />}
                <Outlet />
                </span>
                {user?.module === ModuleNames.ENTERPRISE && 
                <FooterContainer><Footer><p>Powered by</p><img src={HCLEnterpriseLogo} alt='' /></Footer></FooterContainer>}
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default DashboardLayout;
