import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { greeting, Routes, S3Folders, ModuleNames, Roles, federations, storageKeys } from '../Static/constants';
import useAuth from '../hooks/useAuth';
import { uploadFiletoS3 } from '../services/s3Service';
import updateUserAvatar from '../api/requests/updateUserAvatar';
import getUserProfile from '../api/requests/getUserProfile';
import KeycloakServices from '../KeycloakConfig';
import { useMsal } from '@azure/msal-react';
import { keycloakLogout } from '../utils/common';

export const { REACT_APP_BE_DOC_URL = '' } = process.env;

const TopNavContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 1rem 1rem;
`
const TitleContainer = styled.div`
display:flex;
padding-left: 1rem;
align-items: center;
margin: 1rem 0rem;
&& p{
    margin: 0; 
}
`
const NavigateContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
gap: 0.25rem;
&& span:hover span{
    visibility: visible;
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
&& input{
    display: none;
}
`
const ImgContainer = styled.img`
vertical-align: bottom;
padding-right: 0.5rem;
width: 100%;
`
const ImageContainer = styled.img`
vertical-align: bottom;
padding-right: 0.5rem;
`

const Username = styled.p`
font-size: 14px;
font-weight: 400;
padding-left: 0.5rem;
`

const Heading = styled.div`
display: flex;
align-items: center;
&& img{
    cursor: pointer;
    margin-right: 1rem;
}
&& img:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 15px 5px;
    border-radius: 50%;
}
`;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;
const ProfilePicContainer = styled.span`
position: relative;
&& img{
    vertical-align: bottom;    
    cursor: pointer;  
    border-radius: 50%;  
}
&& .profile-pic{
    position: relative;
}
&& span{
    position: absolute;
    left: 0px;
    top: 0px;
    visibility: hidden;
}
&& span .upload-pic{
    padding: 0;
    width: 75%;
}
&& p{
    margin: 0;
    position: absolute;
    top: -23px;
    font-size: 11px;
    display: none;
    width: max-content;
    left: -20px;
}
&&:hover p{ 
    display: block;
}
`

const EnterpriseContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CampusContainer = styled.p`
  background-color: #CCF6FF;
  padding: 0.1rem 0.4rem;
  border-top: 2px solid #006BB6;
  border-radius: 0px 0px 8px 8px;
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #008BAA;
`

const LogoutImgContainer = styled.div`
position: relative;
&& p{
    margin: 0;
    position: absolute;
    top: -21px;
    font-size: 11px;
    left: -5px;
    display: none;
}
&& svg{
    vertical-align: bottom;
padding: 0rem 0.5rem;
cursor: pointer;
}
&&:hover svg path{
    fill: #008BAA;
}
&&:hover p{ 
    display: block;
}
`

const greetingIcon = '/images/greeting.svg';
const defaultUser = '/images/default_user.svg';
const uploadLogo = '/images/upload_logo.svg';
const backIcon = '/images/back_icon.svg';
const hclTechLogo = '/images/HCLTech.png';

const TopNavigation = () => {
    const location = useLocation();
    const [gesture, setGesture] = useState(greeting.Morning);
    const inputRef = useRef<HTMLInputElement>(null);
    const { instance } = useMsal();
    const currentTime = (new Date()).getHours();
    const { user, headerTitle } = useAuth();
    const [ imgUrl, setImgUrl] = useState<string>();
    const navigate = useNavigate();
    

    const handleLogout = () => {
         // if (localStorage.getItem(storageKeys.SignInWith) === federations.AZURE) {
        //     localStorage.removeItem(storageKeys.SignInWith);
        //     localStorage.removeItem(storageKeys.AuthToken);
        //     instance.logoutRedirect({
        //         postLogoutRedirectUri: "/"
        //     });
        // }
        // if (localStorage.getItem(storageKeys.SignInWith) === federations.KEYCLOAK) {
            // navigate(Routes.Logout);
            keycloakLogout();
        // }
    }
    const uploadHandler = () => {
        inputRef.current?.click();
    }
    const profilePicHandler = (event: any) => {
        event.preventDefault();
        const file = event.target.files[0];
        uploadFiletoS3(file, S3Folders.profiles).then(async (data: any) => {
            await updateUserAvatar({ profilePicture: data.key, userId: user?.id as string });
            const profileData = await getUserProfile();
            setImgUrl(profileData?.data?.user?.profilePicture)
        }).catch((err: Error) => {
            // toast.error(err?.message);
        });
    }

    useEffect(() => {
        // After 4 clock
        if (currentTime >= 16) {
            setGesture(greeting.Evening);
        } else if (currentTime >= 13) {
            setGesture(greeting.Afternoon)
        }
    }, [currentTime])

    useEffect(() => {
        setImgUrl(user?.profilePicture ?? '');
    }, [user]);
    
    let profileImg = imgUrl;
    if(!imgUrl?.includes(REACT_APP_BE_DOC_URL)) {
        profileImg = REACT_APP_BE_DOC_URL + '/' + imgUrl;
    }

    return (
        <MainContainer>
            <TopNavContainer>
                {/* {location.pathname === Routes.Dashboard || location.pathname === Routes.CRMDashboard ? */}
                        {/* <TitleContainer>
                            <ImageContainer src={greetingIcon} alt='greetingIcon' height={30}/>
                           <p>Good {gesture}...!</p>
                        </TitleContainer> */}
                        <Heading>{headerTitle?.backIcon && <img src={backIcon} alt='' height={22} width={22} onClick={() => navigate(headerTitle.route)}/>}<h2>{headerTitle?.title}</h2></Heading>
                <NavigateContainer>
                    <ProfilePicContainer><img className='profile-pic' src={imgUrl ? profileImg : defaultUser} height={40} width={40} alt='User Profile' />
                        <p>{imgUrl ? 'Update Profile' : 'Upload Profile'}</p>
                        <span><img className='upload-pic' src={uploadLogo} alt='upload' onClick={uploadHandler} /></span>
                        <input ref={inputRef} type='file' accept='image/*' onChange={profilePicHandler} /></ProfilePicContainer>
                    <Username>{user?.name}</Username>
                    <LogoutImgContainer>
                        <p>Logout</p>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleLogout()}>
<path d="M13.2094 4.19999C13.2093 4.11351 13.1906 4.02805 13.1545 3.94948C13.1183 3.87091 13.0656 3.80108 13 3.74478C12.9343 3.68848 12.8573 3.64703 12.7741 3.62328C12.691 3.59953 12.6037 3.59405 12.5182 3.60719L4.11818 4.89119C3.97655 4.91297 3.8474 4.98471 3.75408 5.09344C3.66076 5.20217 3.60943 5.34071 3.60938 5.48399V18.516C3.60943 18.6593 3.66076 18.7978 3.75408 18.9065C3.8474 19.0153 3.97655 19.087 4.11818 19.1088L12.5182 20.394C12.6037 20.4071 12.691 20.4016 12.7741 20.3779C12.8573 20.3542 12.9343 20.3127 13 20.2564C13.0656 20.2001 13.1183 20.1303 13.1545 20.0517C13.1906 19.9731 13.2093 19.8877 13.2094 19.8012V12H19.4158L18.2194 13.0488C18.1068 13.1554 18.0398 13.3014 18.0325 13.4563C18.0252 13.6112 18.0782 13.7629 18.1803 13.8796C18.2824 13.9963 18.4257 14.069 18.5802 14.0823C18.7347 14.0956 18.8884 14.0487 19.009 13.9512L21.4042 11.8512C21.4684 11.7949 21.5199 11.7255 21.5552 11.6477C21.5904 11.5699 21.6087 11.4854 21.6087 11.4C21.6087 11.3146 21.5904 11.2301 21.5552 11.1523C21.5199 11.0745 21.4684 11.0051 21.4042 10.9488L19.009 8.84879C18.8893 8.74408 18.733 8.6912 18.5743 8.70178C18.4156 8.71236 18.2677 8.78553 18.163 8.90519C18.0583 9.02486 18.0054 9.18122 18.016 9.33987C18.0265 9.49853 18.0997 9.64648 18.2194 9.75119L19.4158 10.8H13.2094V4.19999ZM10.2094 13.5C9.97068 13.5 9.74176 13.4052 9.57298 13.2364C9.4042 13.0676 9.30938 12.8387 9.30938 12.6C9.30938 12.3613 9.4042 12.1324 9.57298 11.9636C9.74176 11.7948 9.97068 11.7 10.2094 11.7C10.4481 11.7 10.677 11.7948 10.8458 11.9636C11.0146 12.1324 11.1094 12.3613 11.1094 12.6C11.1094 12.8387 11.0146 13.0676 10.8458 13.2364C10.677 13.4052 10.4481 13.5 10.2094 13.5ZM15.0094 19.2H14.4094V13.2H15.6094V18.6C15.6094 18.7591 15.5462 18.9117 15.4336 19.0243C15.3211 19.1368 15.1685 19.2 15.0094 19.2ZM14.4094 9.59999V4.79999H15.0094C15.1685 4.79999 15.3211 4.86321 15.4336 4.97573C15.5462 5.08825 15.6094 5.24086 15.6094 5.39999V9.59999H14.4094Z" fill="#808080"/>
</svg>
                    </LogoutImgContainer>
                    {user?.role?.name && user?.module !== ModuleNames.ENTERPRISE && <EnterpriseContainer>
                       <ImgContainer src={[Roles.CRM_ADMIN, Roles.CRM_SUPER_ADMIN].includes(user?.role?.name) ? hclTechLogo : user?.enterprises[0]?.logo} alt='Logo' height={40} />
                      {user?.campus[0]?.name && 
                        <CampusContainer>
                          {user?.campus[0]?.name}
                        </CampusContainer>
                      }
                    </EnterpriseContainer>}
                </NavigateContainer>
            </TopNavContainer>
        </MainContainer>
    )
}
export default TopNavigation;
